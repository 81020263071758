<template>
  <div class="fin-widget">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FinanceiroWidget"
}
</script>
