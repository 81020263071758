<template>
  <div style="width: 100%" id="container-chart"></div>
</template>

<script>
import Highcharts from 'highcharts'
import {number_format as numberFormat} from "@/utils/phpjs";
export default {
  name: "FluxoCaixaChart",
  data () {
    return {
      //saldo: [2000, 2000, -1000.25, 1000.25, 0, 2000, 0, 0, 0, 0, 0, 0],
      //recebimentos: [0, 2000, 0, 1000, 0, 2000, 0, 0, 0, 0, 0, 0],
      //pagamentos: [0, -1000, -3000, 0, 0, -2000.25, 0, 0, 0, 0, 0, 0],
      saldo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      recebimentos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      pagamentos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      intervalo: ['11 Fev', '12 Fev', '13 Fev', '14 Fev', '15 Fev', '16 Fev', '17 Fev', '18 Fev', '19 Fev', '20 Fev', '21 Fev', '22 Fev']
    }
  },
  mounted() {
    this.gerarGrafico()
  },
  methods: {
    gerarGrafico() {
      this.$nextTick(() => {
        // this.identificados = this.acessos.identificados.map(v => v[1])
        // this.anonimos = this.acessos.anonimos.map(v => v[1])

        Highcharts.setOptions({
          lang: {
            thousandsSep: '.'
          }
        })

        this.chart = new Highcharts.chart('container-chart', {

          chart: {
            height: 300,
            type: 'column'
          },
          title: {
            text: null
          },

          yAxis: {
            title: {
              text: null
            }
          },

          xAxis: {
            accessibility: {
              // rangeDescription: 'Range: 2010 to 2017'
            },
            categories: this.intervalo
          },


          legend: {
            verticalAlign: 'top'
          },
          /*legend: {
            layout: 'vertical',
            align: 'left',
            x: 120,
            verticalAlign: 'top',
            y: 100,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
          },*/


          tooltip: {
            formatter: function () {
              let value = this.y
              let prevalue = ''
              if (value < 0) {
                prevalue = '-'
              }
              return '<b>' + this.x + '</b><br/>' +
                  this.series.name + `: ${prevalue}R$` + numberFormat(Math.abs(value), 2, ',', '.')
            }
          },
          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },

          series: [
            {
              name: 'Recebimentos',
              data: this.recebimentos,
              color: '#4AB857'
            },
            {
              name: 'Pagamentos',
              data: this.pagamentos,
              color: '#F43E61'
            },
            {
              name: 'Saldo',
              type: 'line',
              data: this.saldo,
              color: '#395B7B'
            }
          ],

          responsive: {
            rules: [{
              condition: {},
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
                }
              }
            }]
          }

        })
      })
    }
  }
}
</script>
