<template>
  <div class="bank-icon" :class="icon"></div>
</template>

<script>
const bankCodes = {
  1: 'bb',
  237: 'bradesco',
  999: 'sicoob',
  33: 'santander',
  77: 'inter',
  341: 'itau' // @TODO: @JoaoPaulo, fazer relação de códigos com nome da classe e inserir a classe no bank-icons.styl
}

export default {
  name: "BancoIcon",
  props: ['bankCode'],
  computed: {
    icon () {
      if (typeof bankCodes[this.bankCode] !== 'undefined') {
        return bankCodes[this.bankCode]
      }
      return ''
    }
  }
}
</script>
