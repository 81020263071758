<template>
  <div class="h-full">
    <div class="fin-dashboard">
      <e-row class="dash-row">
        <e-col>
          <e-row>
            <e-col>
              <financeiro-widget class="widget-fin-values">
                <div class="title">A receber hoje</div>
                <div class="value" :class="{zero: !response.stats || Number(response.stats.contasReceberMes.valor) <= 0}">R${{ (response.stats && response.stats.contasReceber.hoje.valor)|moeda }}</div>
                <div class="subvalue" :class="{zero: !response.stats || Number(response.stats.contasReceberMes.valor) <= 0}">Restante do mês: R$ {{ (response.stats && response.stats.contasReceberMes.valor)|moeda }}</div>
                <div class="footer">
                  <u-btn @click="$router.push({name: 'financeiro.contas', hash: '#receber', query: {new: '1'}})" class="fin-btn1"
                         label="Novo Recebimento" no-caps/>
                </div>
              </financeiro-widget>
              <financeiro-widget class="widget-fin-values">
                <div class="title">Recebimentos em atraso</div>
                <div class="value" :class="{zero: !response.stats || Number(response.stats.vendasEmAtraso.valor) <= 0}">R${{ (response.stats && response.stats.vendasEmAtraso.valor)|moeda }}</div>
                <div class="subvalue zero transparent">-</div>
                <div class="footer">
                  <div class="subvalue2" v-if="!response.stats || (Number(response.stats.vendasEmAtraso.valor) <= 0)">Não existe recebimentos em atraso</div>
                  <div class="subvalue2" v-else>{{ response.stats.vendasEmAtraso.total }} recebimento{{Number(response.stats.vendasEmAtraso.total) > 1 ? 's' : ''}} em atraso</div>
                </div>
              </financeiro-widget>
            </e-col>
            <e-col>
              <financeiro-widget class="widget-fin-values">
                <div class="title">A pagar hoje</div>
                <div class="value negative" :class="{zero: !response.stats || Number(response.stats.contasPagarMes.valor) <= 0}">R${{ (response.stats && response.stats.contasPagar.hoje.valor)|moeda }}</div>
                <div class="subvalue" :class="{zero: !response.stats || Number(response.stats.contasPagarMes.valor) <= 0}">Restante do mês: R$ {{ (response.stats && response.stats.contasPagarMes.valor)|moeda }}</div>
                <div class="footer">
                  <u-btn @click="$router.push({name: 'financeiro.contas', hash: '#pagar', query: {new: '1'}})" class="fin-btn1" label="Novo Pagamento" no-caps/>
                </div>
              </financeiro-widget>
              <financeiro-widget class="widget-fin-values">
                <div class="title">Pagamentos em atraso</div>
                <div class="value negative" :class="{zero: !response.stats || Number(response.stats.contasPagarEmAtraso.valor) <= 0}">R${{ (response.stats && response.stats.contasPagarEmAtraso.valor)|moeda }}</div>
                <div class="subvalue zero transparent">-</div>
                <div class="footer">
                  <div class="subvalue2" v-if="!response.stats || (Number(response.stats.contasPagarEmAtraso.valor) <= 0)">Não existe pagamentos em atraso</div>
                  <div class="subvalue2" v-else>{{ response.stats.contasPagarEmAtraso.total }} pagamento{{Number(response.stats.contasPagarEmAtraso.total) > 1 ? 's' : ''}} em atraso</div>
                </div>
              </financeiro-widget>
            </e-col>
          </e-row>
          <e-row>
            <e-col>
              <financeiro-widget class="widget-fin-chart widget-fin-values">
                <div class="title">Fluxo de Caixa Diário</div>
                <fluxo-caixa-chart/>
              </financeiro-widget>
            </e-col>
          </e-row>
        </e-col>

        <e-col style="max-width: 328px">
          <contas-widget />
        </e-col>
      </e-row>
    </div>
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import FinanceiroWidget from "@/components/financeiro/components/dashboard/Widget"
import FluxoCaixaChart from "@/components/financeiro/components/FluxoCaixaChart"
import {stats} from "@/domain/financeiro/services"
import ContasWidget from "@/components/financeiro/components/dashboard/ContasWidget"

export default {
  name: "Dashboard",
  data () {
    return {
      response: {}
    }
  },
  components: {ContasWidget, FluxoCaixaChart, FinanceiroWidget, ECol, ERow},
  mounted() {
    this.load()
  },
  methods: {
    load() {
      stats()
          .then(response => {
            this.response = response.data
          })
          .catch(error => {
            this.alertApiError()
          })
    }
  }
}
</script>
