<template>
  <financeiro-widget class="fin-banks">
    <div class="header">
      <div class="lbl">Contas</div>
      <div class="value">R${{ saldoTotal|moeda }}</div>
    </div>
    <div v-if="loading" class="flex flex-center">
      <sl-loading content="" class="size-18 m-t m-b-xxl"/>
    </div>
    <template v-else>
      <div v-for="conta in contas" :key="conta.id" class="item">
        <banco-icon :bank-code="conta.codigoBanco" />
        <div class="lbl">
          <div class="type">Conta Corrente</div>
          <div class="description">{{ conta.nome }}</div>
        </div>
        <div class="value">R${{ conta.saldo|moeda }}</div>
      </div>
    </template>
    <!--    <div class="item">
          <div class="bank-icon itau"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">Itaú</div>
          </div>
          <div class="value">R$204.312,17</div>
        </div>
        <div class="item">
          <div class="bank-icon bradesco"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">Bradesco PJ</div>
          </div>
          <div class="value">R$37.239,17</div>
        </div>
        <div class="item">
          <div class="bank-icon sicoob"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">Sicoob PJ</div>
          </div>
          <div class="value">R$1.000,00</div>
        </div>
        <div class="item">
          <div class="bank-icon santander"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">Santander</div>
          </div>
          <div class="value">R$8.200,00</div>
        </div>
        <div class="item">
          <div class="bank-icon bb"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">BB PJ</div>
          </div>
          <div class="value">R$739,00</div>
        </div>
        <div class="item">
          <div class="bank-icon inter"></div>
          <div class="lbl">
            <div class="type">Conta Corrente</div>
            <div class="description">Inter Digital</div>
          </div>
          <div class="value">R$9.808,00</div>
        </div>-->
    <div class="item no-hover">
      <div class="cursor-pointer">
        <u-icon name="link" type="fa" class="m-r-xs"/>
        <a @click="$router.push({name: 'bancos.main'})">Conectar banco</a></div>
    </div>
  </financeiro-widget>
</template>

<script>
import FinanceiroWidget from "@/components/financeiro/components/dashboard/Widget"
import {listBancos} from "@/domain/cadastro/services"
import SlLoading from "@/components/layout/components/Loading"
import BancoIcon from "@/components/financeiro/components/banco/BancoIcon"

export default {
  name: "ContasWidget",
  components: {BancoIcon, SlLoading, FinanceiroWidget},
  data() {
    return {
      loading: true,
      contas: null
    }
  },
  computed: {
    saldoTotal () {
      if (!this.contas) {
        return 0
      }
      let saldo = 0
      this.contas.map(conta => {
        saldo = saldo + conta.saldo
      })
      return saldo
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      listBancos(100, 1, '')
          .then(response => {
            this.contas = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
